<template>
  <WidgetCard :is-loading="isLoading">
    <template #heading>
      <template>Dose types</template>
    </template>
    <template>
      <div
        v-for="(insulin, index) in patient.insulinTypes"
        :key="`primary_dose_${index}`"
        :class="{ 'pb-2': !isLastInsulin(index) }"
      >
        {{ $t(`insulin-type.${insulin.type}`) }}
        <template v-if="!isLastInsulin(index)">&amp;</template>
      </div>
    </template>
  </WidgetCard>
</template>

<script>
import WidgetCard from '../_WidgetCard'

export default {
  name: 'DoubleDummyTreatmentStatus',
  components: { WidgetCard },
  props: {
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
  },
  methods: {
    isLastInsulin(index) {
      return index === this.patient.insulinTypes.length - 1
    },
  },
}
</script>
