<template>
  <WidgetCard
    :is-loading="isLoading"
    smallText
    data-testid="current-bolus-dose-widget"
  >
    <template #heading>Current prescribed bolus doses</template>
    <div
      v-for="(dose, index) in sortedFilteredDoses"
      :key="`primary_dose_${index}`"
      class="d-flex justify-space-between align-center relative"
      :class="{
        'is-deleted': isDeleted,
      }"
    >
      <div class="d-flex align-center">
        <BolusTimepointIcon
          :use-deleted-color="isDeleted"
          :time-point="dose.timepoint"
          small
        />
        <span>{{ $t('time-point-short.' + dose.timepoint) }}</span>
      </div>
      <span>{{
        !dose.doseValue ? '-' : `${dose.doseValue} ${dose.dosingDisplayUnit}`
      }}</span>
    </div>
  </WidgetCard>
</template>

<script>
import BolusTimepointIcon from '@/components/BolusTimepointIcon'
import WidgetCard from '../_WidgetCard'
import timepointKeys from '@/constants/timepointKeys'

export default {
  name: 'CurrentBolusTitrationDoseWidget',
  components: { WidgetCard, BolusTimepointIcon },
  props: {
    prescriptions: { type: Array, required: true },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    isDeleted() {
      return this.prescriptions[0]?.isDeleted
    },
    sortedFilteredDoses() {
      const bolusOrder = [
        timepointKeys.bolus_breakfast,
        timepointKeys.bolus_lunch,
        timepointKeys.bolus_dinner,
        timepointKeys.bolus_extra_prescription,
      ]
      const doses = this.prescriptions[0]?.doses || [] // support only one bolus prescription at the time

      doses.sort(
        (a, b) =>
          bolusOrder.indexOf(a.timepoint) - bolusOrder.indexOf(b.timepoint)
      )

      return doses.filter(d => d.isActive)
    },
  },
}
</script>
