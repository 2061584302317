<template>
  <v-card
    class="rounded-lg widget-card fill-height"
    :class="{ landscape: landscape }"
  >
    <v-card-text class="fill-height d-flex flex-column">
      <v-row>
        <v-col class="spacing-2 widget-heading py-0 mb-4">
          <v-skeleton-loader
            type="text"
            width="130"
            class="d-inline-block"
            :loading="isLoading"
            data-testid="widget-heading"
          >
            <slot name="heading" />
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="fill-height">
        <v-col class="align-self-end py-0">
          <v-skeleton-loader type="heading" :loading="isLoading">
            <slot name="chip" />
            <h6 class="big-text" v-if="smallText" data-testid="widget-text">
              <slot />
            </h6>
            <h5 class="big-text" v-else data-testid="widget-text">
              <slot />
            </h5>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'WidgetCard',
  props: {
    isLoading: { type: Boolean, required: true, default: false },
    smallText: { type: Boolean, required: false, default: false },
  },
  computed: {
    landscape() {
      return this.$vuetify.breakpoint.mdAndUp
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-card {
  min-height: 128px;

  .widget-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: $nn-granite-gray;
  }
  .big-text {
    color: $nn-D_T20;
  }
}

.landscape {
  min-height: 164px;
}
</style>
