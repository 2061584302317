<template>
  <div
    v-if="hasPendingActions"
    class="hypo-list-widget"
    data-testid="hypo-list-widget"
  >
    <v-card class="rounded-lg">
      <v-card-text>
        <v-row class="no-gutters">
          <v-col
            class="spacing-2 widget-heading mb-2"
            data-testid="widget-heading"
          >
            Hypoglycemic episodes — Pending tasks
          </v-col>
        </v-row>
      </v-card-text>
      <HypoFormTable
        :patientNo="patientNo"
        :items="items"
        :hideTopPagination="true"
      />
    </v-card>
  </div>
</template>

<script>
import HypoFormTable from '../hypo-form/HypoFormTable'
import { hypoListMapGetters } from '@/store/modules/hypoListModule'

export default {
  name: 'HypoListWidget',
  components: { HypoFormTable },
  props: { patientNo: { type: String, required: true } },
  computed: {
    ...hypoListMapGetters(),
    numberOfPendingActions() {
      return this.hypoListNonReviewedItems.length
    },
    hasPendingActions() {
      return this.numberOfPendingActions > 0
    },
    items() {
      return this.hypoListNonReviewedItems
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: $nn-granite-gray;
}
</style>
