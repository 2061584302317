<template>
  <div class="widget-heading">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WidgetHeading',
}
</script>

<style lang="scss" scoped>
.widget-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: $nn-granite-gray;
}
</style>
