
import Vue from 'vue'
import appStateMixin from '@/components/patient/appStateMixin'
import InlineWidgetCard from '@/components/patientSummary/_InlineWidgetCard.vue'
import StatusDot from '@/components/patientSummary/_StatusDot.vue'

export default Vue.extend({
  name: 'AppStatusWidget',
  components: { StatusDot, InlineWidgetCard },
  mixins: [appStateMixin],
  computed: {
    loading() {
      return !this.appState
    },
  },
})
