<template>
  <WidgetCard :is-loading="isLoading">
    <template #heading>
      <template v-if="singleRegimen">Treatment arm</template>
      <template v-else>Treatment arm &amp; bolus regimen</template>
    </template>
    <template v-if="singleRegimen">{{ treatmentArmDisplayName }}</template>
    <template v-else>
      {{ $t(`insulin-type.${basalInsulin.type}`) }}<br />
      {{ $t(`dosing-regimen.${dosingRegimen}`) }}
    </template>
  </WidgetCard>
</template>

<script>
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'
import WidgetCard from '../_WidgetCard'

export default {
  name: 'BolusTreatmentStatus',
  components: { WidgetCard },
  props: {
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    ...trialSettingsMapGetters(),
    treatmentArmDisplayName() {
      return this.trialTreatmentName(this.patient.treatmentArmId)
    },
    singleRegimen() {
      return this.trialDosingRegimens.length === 1
    },
    basalInsulin() {
      return this.patient.insulinTypes.find(a => a.type.startsWith('basal'))
    },
    dosingRegimen() {
      return this.patient.dosingRegimen
    },
  },
}
</script>
