
import Vue from 'vue'
import DateFormat from '@/components/DateFormat.vue'
import InlineWidgetCard from '@/components/patientSummary/_InlineWidgetCard.vue'
import hourFormatter from '@/utils/date/hourFormatter'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'

export default Vue.extend({
  name: 'AppActivityWidget',
  components: { InlineWidgetCard, DateFormat },
  props: {
    isPatientLoading: { type: Boolean, required: true },
    appLastSyncedAt: { type: Date, required: true },
  },
  computed: {
    ...selectedPatientMapGetters(),
    timeFormatString() {
      return hourFormatter(this.selectedPatientClockNotation(this.patientNo))
    },
  },
})
