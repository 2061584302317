<template>
  <v-card
    v-if="reminderState !== 'none'"
    class="rounded-lg patient-pro-reminder-widget pending"
    data-testid="pro-reminder-widget"
  >
    <v-card-text
      v-if="reminderState === 'pending'"
      class="content"
      data-testid="pro-reminder-state-pending"
    >
      <v-row no-gutters class="d-flex" align="center">
        <v-col class="shrink">
          <v-icon>mdi-alert-outline</v-icon>
        </v-col>
        <v-col class="grow pl-2"
          ><strong>Visit {{ pendingVisitNumber }} :</strong> Remember to send
          PRO questionnaires to the patient
        </v-col>
        <v-col class="shrink">
          <v-btn
            small
            rounded
            color="secondary"
            :to="{ name: 'proQuestionnaires' }"
            type="submit"
            class="elevation-1 btn-close"
            data-testid="btn-close"
            >Go to PROs</v-btn
          ></v-col
        >
      </v-row>
    </v-card-text>

    <v-card-text
      v-if="reminderState === 'not-submitted'"
      class="content"
      data-testid="pro-reminder-state-not-submitted"
    >
      <v-row no-gutters class="d-flex" align="center">
        <v-col class="shrink">
          <v-icon>mdi-alert-outline</v-icon>
        </v-col>
        <v-col class="grow pl-2"
          ><strong>PRO questionnaires</strong> is not submitted by patient.
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import loadingState from '@/constants/loadingState'
import service from '@/services/pro-service'
import Vue from 'vue'

export default {
  name: 'ProReminderWidget',
  props: { patientNo: { type: String, required: true } },
  data() {
    return {
      loadingState: loadingState.INITIAL,
      items: [],
    }
  },
  computed: {
    reminderState() {
      if (this.pendingVisitNumber) return 'pending'
      if (this.hasPendingSubmit) return 'not-submitted'

      return 'none'
    },
    pendingVisitNumber() {
      return this.items.find(x => x.pros.some(x => x.isSendPending))?.visitId
    },
    hasPendingSubmit() {
      return this.items.some(x =>
        x.pros.some(y => y.isSent && !y.isCompleted && !y.isExpired)
      )
    },
  },
  methods: {
    fetchProList() {
      this.loadingState = loadingState.LOADING

      service
        .getProsList(this.patientNo)
        .then(response => {
          this.items = response
          this.loadingState = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    this.fetchProList()
  },
}
</script>

<style lang="scss" scoped>
.patient-pro-reminder-widget {
  &.pending {
    background: $nn-GS_T90;
  }
}
.content {
  font-size: 14px;
  font-weight: 500;
}
</style>
