<template>
  <PatientPage :patientNo="patientNo" :showHeader="false">
    <v-row>
      <v-col class="pb-1">
        <v-card elevation="0">
          <v-card-text class="py-0">
            <v-row class="d-flex align-center">
              <v-col cols="4">
                <h3>Patient {{ patientNo }}</h3>
              </v-col>
              <v-col cols="3" class="patient-status-col">
                <PatientStatusWidget :state="patientState" />
              </v-col>
              <v-col cols="2" class="flex-shrink-0">
                <AppStatusWidget :appState="appState" />
              </v-col>
              <v-col cols="3" class="flex-shrink-0">
                <AppActivityWidget
                  :isPatientLoading="isPatientLoading"
                  :appLastSyncedAt="appLastSyncedAt"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="shouldRenderPatientAppStateWidget">
      <v-col class="pb-1">
        <PatientAppStateWidget :patientNo="patientNo" :appState="appState" />
      </v-col>
    </v-row>
    <v-row v-if="prosEnabled">
      <v-col class="pb-1">
        <ProReminderWidget :patientNo="patientNo" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :sm="cardColSpan" class="flex-grow-1">
        <TreatmentStatusWidget
          :patient="patient"
          :isLoading="isPatientLoading"
        />
      </v-col>
      <v-col v-if="prescriptionsEnabled" :sm="cardColSpan" class="flex-grow-1">
        <CurrentDoseWidget
          :patient="patient"
          :prescriptions="basalPrescriptions"
          :isLoading="isCurrentDoseWidgetLoading"
        />
      </v-col>
      <v-col
        v-if="prescriptionsEnabled && anyBolusTitrationPrescription"
        sm="cardColSpan"
        class="flex-grow-1"
      >
        <CurrentBolusTitrationDoseWidget
          :prescriptions="bolusTitrationPrescription"
          :isLoading="isCurrentDoseWidgetLoading"
        />
      </v-col>
      <v-col :sm="cardColSpan" class="flex-grow-1">
        <HypoStatusWidget />
      </v-col>
    </v-row>
    <v-row v-if="anyBolusCarbCountPrescription">
      <v-col>
        <CurrentBolusCarbCountDoseWidget
          :prescriptions="bolusCarbCountPrescriptions"
          :isLoading="isCurrentDoseWidgetLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <HypoListWidget :patientNo="patientNo" />
      </v-col>
    </v-row>
  </PatientPage>
</template>

<script>
import Vue from 'vue'
import prescriptionService from '@/services/prescription-service'
import patientService from '@/services/patient-service'

import PatientStatusWidget from '@/components/patientSummary/PatientStatusWidget.vue'
import CurrentDoseWidget from '@/components/patientSummary/CurrentDoseWidget/CurrentDoseWidget.vue'
import HypoListWidget from '@/components/patientSummary/HypoListWidget.vue'
import HypoStatusWidget from '@/components/patientSummary/HypoStatusWidget.vue'
import PatientAppStateWidget from '@/components/patientSummary/PatientAppStateWidget.vue'
import PatientPage from '@/components/patient/PatientPage.vue'
import AppStatusWidget from '@/components/patientSummary/AppStatusWidget.vue'
import AppActivityWidget from '@/components/patientSummary/AppActivityWidget.vue'
import TreatmentStatusWidget from '@/components/patientSummary/TreatmentStatusWidget/TreatmentStatusWidget.vue'
import ProReminderWidget from '@/components/proQuestionnaires/ProReminderWidget'
import CurrentBolusTitrationDoseWidget from '@/components/patientSummary/CurrentDoseWidget/CurrentBolusTitrationDoseWidget.vue'
import CurrentBolusCarbCountDoseWidget from '@/components/patientSummary/CurrentDoseWidget/CurrentBolusCarbCountDoseWidget.vue'

import loadingState from '@/constants/loadingState'
import { hypoListMapActions } from '@/store/modules/hypoListModule'
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'
import globalProgressActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'
import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'
import prescriptionType from '@/constants/prescriptionType'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'

export default {
  name: 'Patient',
  mixins: [
    selectedPatientGettersMixin,
    permissionsGettersMixin,
    globalProgressActionsMixins,
  ],
  components: {
    ProReminderWidget,
    HypoStatusWidget,
    HypoListWidget,
    PatientAppStateWidget,
    PatientPage,
    AppStatusWidget,
    AppActivityWidget,
    CurrentDoseWidget,
    TreatmentStatusWidget,
    PatientStatusWidget,
    CurrentBolusTitrationDoseWidget,
    CurrentBolusCarbCountDoseWidget,
  },
  props: { patientNo: { type: String, required: true } },
  data() {
    return {
      patient: {},
      prescriptions: [],
      patientLoadingState: loadingState.INITIAL,
      prescriptionsLoadingState: loadingState.INITIAL,
    }
  },
  computed: {
    ...trialSettingsMapGetters(),
    prescriptionsEnabled() {
      return this.trialFeatureFlags(this.patientNo).prescriptionsEnabled
    },
    prosEnabled() {
      return this.trialFeatureFlags(this.patientNo).prosEnabled
    },
    appState() {
      return this.selectedPatientConfiguration(this.patientNo)?.appState || ''
    },
    appLastSyncedAt() {
      return new Date(this.patient.lastSyncedAtUtc) || ''
    },
    patientState() {
      return (
        this.selectedPatientConfiguration(this.patientNo)?.patientState || {}
      )
    },
    shouldRenderPatientAppStateWidget() {
      return (
        this.isUserAllowedToManagePatient &&
        (this.appState === 'pending' || this.appState === 'blocked') &&
        this.patientState.currentState !==
          patientConfigurationPatientState.COMPLETED
      )
    },
    basalPrescriptions() {
      return this.prescriptions.filter(
        d =>
          d.type === prescriptionType.BASAL_ONCE_DAILY ||
          d.type === prescriptionType.BASAL_ONCE_WEEKLY ||
          d.type === prescriptionType.DOUBLE_DUMMY
      )
    },
    bolusTitrationPrescription() {
      return this.prescriptions.filter(d => d.type === prescriptionType.BOLUS)
    },
    bolusCarbCountPrescriptions() {
      return this.prescriptions.filter(
        d => d.type === prescriptionType.BOLUS_CARB_COUNT
      )
    },
    landscape() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    anyBolusTitrationPrescription() {
      return this.bolusTitrationPrescription.length > 0
    },
    cardColSpan() {
      if (!this.anyBolusTitrationPrescription) return 4

      if (this.landscape) return 3

      return 6
    },
    anyBolusCarbCountPrescription() {
      return this.bolusCarbCountPrescriptions.length > 0
    },
    isPatientLoading() {
      return this.patientLoadingState === loadingState.LOADING
    },
    isCurrentDoseWidgetLoading() {
      return (
        this.patientLoadingState === loadingState.LOADING ||
        this.prescriptionsLoadingState === loadingState.LOADING
      )
    },
  },
  methods: {
    ...hypoListMapActions(),
    loadPatient() {
      this.patientLoadingState = loadingState.LOADING
      this.globalStartLoadingRequested()

      patientService
        .getPatient(this.patientNo)
        .then(response => {
          this.patientLoadingState = loadingState.LOAD_SUCCEEDED
          this.patient = response.data
        })
        .catch(error => {
          this.patientLoadingState = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
    loadPrescriptions() {
      this.prescriptionsLoadingState = loadingState.LOADING
      this.globalStartLoadingRequested()

      prescriptionService
        .getCurrentPrimaryDose(this.patientNo)
        .then(response => {
          this.prescriptionsLoadingState = loadingState.LOAD_SUCCEEDED
          this.prescriptions = Array.isArray(response) ? response : [response]
        })
        .catch(error => {
          this.prescriptionsLoadingState = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
  },
  created() {
    this.hypoListLoadDataRequested(this.patientNo)
    this.loadPatient()
    this.loadPrescriptions()
  },
}
</script>

<style lang="scss" scoped>
.patient-status-col {
  min-width: 130px;
}
</style>
