<template>
  <WidgetCard :is-loading="isLoading" data-testid="current-dose-widget">
    <template #heading>{{ widgetHeader }}</template>
    <div v-if="noCurrentDose">No current dose</div>
    <div
      v-else
      v-for="(dose, index) in doses"
      :key="`primary_dose_${index}`"
      class="d-flex justify-space-between relative"
      :class="{
        'pb-2': !isLastDose(index),
        'is-deleted': isDeleted,
      }"
    >
      <span>
        <IconPen
          :class="{
            'small-icon-pen': !isDeleted,
            'is-deleted': isDeleted,
          }"
          class="mr-2"
          :height="14"
          :width="14"
        />
        {{ $t(`time-point${landscape ? '' : '-short'}.${dose.timepoint}`) }}
      </span>
      <span>{{
        !dose.doseValue ? '-' : `${dose.doseValue} ${dose.dosingDisplayUnit}`
      }}</span>
    </div>
  </WidgetCard>
</template>

<script>
import WidgetCard from '../_WidgetCard'
import IconPen from '@/components/icons/IconPen'

export default {
  name: 'CurrentDoseWidget',
  components: { WidgetCard, IconPen },
  props: {
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
    prescriptions: { type: Array, required: true },
  },
  computed: {
    doses() {
      return this.prescriptions[0]?.doses || [] // support only one bolus prescription at the time
    },
    isBolus() {
      return this.patient.insulinTypes?.some(e => e.type === 'bolus')
    },
    multipleDoses() {
      return this.doses.length > 1
    },
    noCurrentDose() {
      return this.doses.length === 0
    },
    widgetHeader() {
      if (this.isBolus && this.multipleDoses) {
        return 'Current prescribed basal doses'
      }
      if (this.isBolus) {
        return 'Current prescribed basal dose'
      }
      if (this.multipleDoses) {
        return 'Current prescribed doses'
      }
      return 'Current prescribed dose'
    },
    landscape() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    isDeleted() {
      return this.prescriptions[0]?.isDeleted
    },
  },
  methods: {
    isLastDose(index) {
      return index === this.doses.length - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.small-icon-pen {
  color: $nn-true-blue;
}
</style>
