var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WidgetCard',{attrs:{"is-loading":_vm.isLoading,"data-testid":"current-dose-widget"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(_vm._s(_vm.widgetHeader))]},proxy:true}])},[(_vm.noCurrentDose)?_c('div',[_vm._v("No current dose")]):_vm._l((_vm.doses),function(dose,index){return _c('div',{key:`primary_dose_${index}`,staticClass:"d-flex justify-space-between relative",class:{
      'pb-2': !_vm.isLastDose(index),
      'is-deleted': _vm.isDeleted,
    }},[_c('span',[_c('IconPen',{staticClass:"mr-2",class:{
          'small-icon-pen': !_vm.isDeleted,
          'is-deleted': _vm.isDeleted,
        },attrs:{"height":14,"width":14}}),_vm._v(" "+_vm._s(_vm.$t(`time-point${_vm.landscape ? '' : '-short'}.${dose.timepoint}`))+" ")],1),_c('span',[_vm._v(_vm._s(!dose.doseValue ? '-' : `${dose.doseValue} ${dose.dosingDisplayUnit}`))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }