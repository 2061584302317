<template>
  <DoubleDummyTreatmentStatus
    v-if="isDoubleDummy"
    :isLoading="isLoading"
    :patient="patient"
    data-testid="treatment-status-widget"
  />

  <BolusTreatmentStatus
    v-else-if="isBolus"
    :isLoading="isLoading"
    :patient="patient"
    data-testid="treatment-status-widget"
  />

  <TreatmentStatus
    v-else
    :isLoading="isLoading"
    :patient="patient"
    data-testid="treatment-status-widget"
  />
</template>

<script>
import TreatmentStatus from './_TreatmentStatus.vue'
import DoubleDummyTreatmentStatus from './_DoubleDummyTreatmentStatus.vue'
import BolusTreatmentStatus from './_BolusTreatmentStatus.vue'

export default {
  name: 'TreatmentStatusWidget',
  components: {
    TreatmentStatus,
    DoubleDummyTreatmentStatus,
    BolusTreatmentStatus,
  },
  props: {
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    isDoubleDummy() {
      return (
        this.patient.insulinTypes?.some(e => e.type === 'basal_once_daily') &&
        this.patient.insulinTypes?.some(e => e.type === 'basal_once_weekly')
      )
    },
    isBolus() {
      return this.patient.insulinTypes?.some(e => e.type === 'bolus')
    },
  },
}
</script>
