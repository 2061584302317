
import { selectedPatientMapActions } from '@/store/modules/selectedPatientModule'
import loadingState from '@/constants/loadingState'
import { updatePatientConfigurationAppState } from '@/utils/updateAppState'
import service from '@/services/app-service'
import Vue from 'vue'

export default Vue.extend({
  name: 'PatientAppStateWidget',
  components: {
    ResumeAppSteps: () =>
      import('@/components/patient/app/_ResumeAppSteps.vue'),
  },
  props: {
    appState: { type: String, required: true },
    patientNo: { type: String, required: true },
  },
  data() {
    return {
      showDialog: false,
      dialogRevision: 0,
    }
  },
  methods: {
    ...selectedPatientMapActions(),
    loadAppState() {
      this.loadingState = loadingState.LOADING
      service
        .getAppState(this.patientNo)
        .then((response: any) => {
          updatePatientConfigurationAppState({
            state: response.state,
            action: this.selectedPatientUpdateAppState,
            patientNumber: this.patientNo,
          })

          this.loadingState = loadingState.LOAD_SUCCEEDED
          this.state = response.state
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    closeDialog() {
      this.loadAppState()
      this.showDialog = false
    },
    resumeActivation() {
      this.dialogRevision += 1
      this.showDialog = true
      this.$nextTick(() => {
        this.$refs.activateAppSteps.callApiGetPendingApp()
      })
    },
  },
})
