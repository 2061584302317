<template>
  <WidgetCard :is-loading="isLoading">
    <template #heading>
      <template>Treatment arm</template>
    </template>
    <template>{{ treatmentArmDisplayName }}</template>
  </WidgetCard>
</template>

<script>
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'
import WidgetCard from '../_WidgetCard'

export default {
  name: 'TreatmentStatus',
  components: { WidgetCard },
  props: {
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    ...trialSettingsMapGetters(),
    treatmentArmDisplayName() {
      return this.trialTreatmentName(this.patient.treatmentArmId)
    },
  },
}
</script>
