
import Vue from 'vue'
import WidgetHeading from '@/components/patientSummary/_WidgetHeading.vue'

export default Vue.extend({
  name: 'InlineWidgetCard',
  components: { WidgetHeading },
  props: {
    loading: { type: Boolean, required: false, default: false },
  },
})
