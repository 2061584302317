
import Vue from 'vue'
import InlineWidgetCard from '@/components/patientSummary/_InlineWidgetCard.vue'
import StatusDot from '@/components/patientSummary/_StatusDot.vue'

export default Vue.extend({
  name: 'PatientStatusWidget',
  components: { StatusDot, InlineWidgetCard },
  props: { state: { type: Object, required: true } },
  computed: {
    loading() {
      return !this.state?.currentState
    },
    getStatusText() {
      if (!this.state?.currentState) return '?'
      return this.$t('patient-trial-status.' + this.state.currentState)
    },
    getStatusColor() {
      switch (this.state.currentState) {
        case 'activated':
          return '#2a918b'
        case 'in_follow_up':
          return '#89c1eb'
        case 'completed':
        default:
          return '#001965'
      }
    },
  },
})
