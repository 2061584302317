<template>
  <WidgetCard
    :is-loading="isLoading"
    smallText
    data-testid="current-carb-count-dose-widget"
  >
    <template #heading>Carbohydrate counting factors</template>
    <v-row class="pt-4">
      <v-col
        class="font-weight-regular col-sm-6"
        :class="{
          'separator pr-4': !isLastDose(index),
          'pl-4': !isFirstDose(index),
          'col-md-4': sortedFilteredDoses.length === 3,
          'col-md-6': sortedFilteredDoses.length === 2,
        }"
        v-for="(dose, index) in sortedFilteredDoses"
        :key="index"
      >
        <v-row>
          <v-col class="d-flex align-center shrink relative">
            <div class="icon-container">
              <BolusTimepointIcon
                :use-deleted-color="isDeleted"
                :time-point="dose.timepoint"
                small
              />
            </div>
            <span class="font-weight-bold" :class="{ 'is-deleted': isDeleted }">
              {{ $t('time-point-short.' + dose.timepoint) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="pb-1" :class="{ 'is-deleted': isDeleted }">
          <v-col class="py-0 relative" sm="7"> Insulin-to-carb ratio </v-col>
          <v-col class="py-0 pl-0 text-right relative" sm="5">
            1 {{ dose.dosingDisplayUnit }} : {{ dose.insulinToCarbRatio }} g
          </v-col>
        </v-row>
        <v-row :class="{ 'is-deleted': isDeleted }">
          <v-col class="py-0 relative" sm="7">
            Insulin sensitivity factor
          </v-col>
          <v-col class="py-0 pl-0 text-right relative" sm="5">
            {{ dose.insulinSensitivityFactor }}
            {{ bloodGlucoseUnit }} per 1{{ dose.dosingDisplayUnit }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </WidgetCard>
</template>

<script>
import WidgetCard from '../_WidgetCard'
import BolusTimepointIcon from '@/components/BolusTimepointIcon'
import timepointKeys from '@/constants/timepointKeys'

export default {
  name: 'CurrentBolusCarbCountDoseWidget',
  components: { WidgetCard, BolusTimepointIcon },
  props: {
    isLoading: { type: Boolean, required: true },
    prescriptions: { type: Array, required: true },
  },
  computed: {
    bloodGlucoseUnit() {
      return this.prescriptions[0]?.bloodGlucoseUnit || '--/--'
    },
    isDeleted() {
      return this.prescriptions[0]?.isDeleted
    },
    sortedFilteredDoses() {
      const bolusOrder = [
        timepointKeys.bolus_breakfast,
        timepointKeys.bolus_lunch,
        timepointKeys.bolus_dinner,
      ]
      const doses = this.prescriptions[0]?.doses || [] // support only one bolus prescription at the time

      doses.sort(
        (a, b) =>
          bolusOrder.indexOf(a.timepoint) - bolusOrder.indexOf(b.timepoint)
      )

      return doses.filter(d => d.isActive)
    },
  },
  methods: {
    isFirstDose(index) {
      return index === 0
    },
    isLastDose(index) {
      return index === this.sortedFilteredDoses.length - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.separator {
  border-right: 1px solid $nn-TB_T95;
}

.icon-container {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: left;
}

.icon-small {
  transform: scale(0.8);
}
</style>
