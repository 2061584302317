<template>
  <WidgetCard :is-loading="hypoListIsLoading" data-testid="hypo-status-widget">
    <template #heading>Hypos since trial start</template>
    <template #chip>
      <v-chip
        v-if="hasPendingActions"
        label
        small
        text-color="#E6553F"
        color="#FEF6F5"
        class="mb-2"
        data-testid="hypo-pending-tasks"
      >
        {{ numberOfPendingActions }} pending
        {{ numberOfPendingActions === 1 ? 'task' : 'tasks ' }}
      </v-chip>
    </template>
    <UnableToLoadData v-if="hypoListLoadErrored"
      >Unable to load data</UnableToLoadData
    >
    <template v-else>
      {{ cardText }}
      <span v-if="hasSevereHypos" class="ml-2 is-hypo"
        >{{ severeHyposText }}
      </span>
    </template>
  </WidgetCard>
</template>

<script>
import { hypoListMapGetters } from '@/store/modules/hypoListModule'
import WidgetCard from './_WidgetCard'

export default {
  name: 'HypoStatusWidget',
  components: {
    WidgetCard,
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
  },
  computed: {
    ...hypoListMapGetters(),
    totalNumberOfHypos() {
      return this.hypoListItems.filter(hypo => !hypo.isDeleted).length
    },
    cardText() {
      var length = this.totalNumberOfHypos
      return (
        (length === 0 ? 'No' : length) +
        (length === 1 ? ' episode' : ' episodes')
      )
    },
    severeHyposText() {
      var length = this.hypoListNoOfSevereHypos.length
      return length + ' severe ' + (length > 1 ? 'hypos' : 'hypo')
    },
    hasSevereHypos() {
      return this.hypoListNoOfSevereHypos.length > 0
    },
    numberOfPendingActions() {
      return this.hypoListNonReviewedItems.length
    },
    hasPendingActions() {
      return this.numberOfPendingActions > 0
    },
  },
}
</script>
